<template>
  <div class="app-store-buttons">
    <AppStoreButton v-if="mobileOs !== 'android'" store="app-store" />
    <AppStoreButton v-if="mobileOs !== 'iOs'" store="google-play" />
  </div>
</template>

<script setup lang="ts">
const { mobileOs } = useUserAgent();
</script>

<style lang="scss" scoped>
.app-store-buttons {
  display: flex;
  gap: 1rem;

  @include for-small-screen {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.gold :deep(.rating) {
  color: $gold;
}
</style>
