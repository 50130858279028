<template>
  <div class="app-store-button">
    <a :href="appStoreLink" target="_blank" @click="logEvent">
      <BaseImage
        :src="`button-${store}.svg`"
        class="app-store-image"
        :alt="$t(`app.${store}-alt`)"
      />
    </a>
    <div class="rating mt-1">
      <BaseStarRating :rating="Number(rating[store])" />
      <BaseText class="rating-score"> ({{ rating[store] }})</BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import { event } from "vue-gtag";
import BaseStarRating from "~/components/atoms/BaseStarRating.vue";

const props = defineProps<{
  store: "app-store" | "google-play";
}>();

const host = useCurrentHost();

const { t } = useI18n();
const rating = {
  "app-store": Number(t("app.app-store-rating")),
  "google-play": Number(t("app.play-store-rating")),
};

const appStoreLink = computed(() => {
  // Allefolders links
  if (host.value === "allefolders") {
    if (props.store === "app-store") {
      return "https://apps.apple.com/nl/app/allefolders/id1086601848/";
    } else {
      return "https://play.google.com/store/apps/details?id=com.jafolders.allefolders";
    }
  }

  // Promopromo links
  if (props.store === "app-store") {
    return "https://apps.apple.com/nl/app/promopromo-folders-van-belgi%C3%AB/id811303467";
  } else {
    return "https://play.google.com/store/apps/details?id=com.jafolders.promopromo";
  }
});

const logEvent = () => {
  // Log analytics event
  event("app-store-button-click", { store: props.store, host: host.value });
};
</script>

<style lang="scss" scoped>
.app-store-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-store-image {
  max-height: 3.25rem;
  max-width: 9rem;
}

.rating {
  display: flex;
  color: $light-100;
  align-items: center;

  .rating-score {
    font-size: 1rem;
    font-weight: 600;
    margin-left: 0.5rem;
  }
}
</style>
